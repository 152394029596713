(() => {
    const methods = {
            getHeaderHeight: () => {
                return document.querySelector('.main-header').offsetHeight;
            }
        },

        globals = {
            desktopMin: 1280,
            mobileMenuMax: 1024,
            scrollAnimationDelay: 100
        };

    const mobileMenu = (() => {

        const button = document.querySelector('.nav-btn'),
            body = document.body,
            menuSelector = '.main-menu',
            openMenuClass = 'menu-open',
            menu = body.querySelector(menuSelector),
            transitionDelay = 300,
            singlePage = true;

        let windowOffset = 0;

        const toggleDocumentScroll = (disable) => {
            if (window.innerWidth <= globals.mobileMenuMax) {
                if (disable) {
                    document.ontouchmove = function(e) {
                        e.preventDefault();
                    };
                } else {
                    document.ontouchmove = function() {
                        return true;
                    };
                }

            } else {
                document.ontouchmove = function() {
                    return true;
                };
            }

        };

        const openMenu = () => {
            body.classList.add(openMenuClass);
            windowOffset = window.pageYOffset;
            toggleDocumentScroll(true);
        };

        const closeMenu = () => {
            body.classList.remove(openMenuClass);
            toggleDocumentScroll(false);
        };

        const getMenuState = () => body.classList.contains(openMenuClass);

        const toggleMenu = () => {
            body.classList.contains(openMenuClass) ? closeMenu() : openMenu();
            window.scroll(0, windowOffset);
        };

        const menuClick = () => {
            setTimeout(() => {
                closeMenu();
            }, transitionDelay);
        };

        const onResize = () => {
            window.addEventListener('resize', () => {
                if (window.innerWidth > globals.mobileMenuMax) {
                    toggleDocumentScroll(false);
                    body.classList.remove(openMenuClass);
                }
            });
        };

        const addEvents = () => {
            onResize();
            button.addEventListener('click', toggleMenu);
            if (singlePage) {
                Array.prototype.slice.call(menu.childNodes).map((link) => link.addEventListener('click', menuClick));
            }
        };

        const init = () => addEvents();

        return {
            init,
            openMenu,
            closeMenu,
            getMenuState
        };

    })();

    const carousel = (() => {

        const $sliderArticles = $('.article-list'),
            $sliderLogos = $('.slider-logos');

        const sliderArticles = () => {

            $sliderArticles.owlCarousel({
                center: true,
                items: 1,
                loop: false,
                margin: 20,
                pullDrag: true,
                mouseDrag: true,
                rewind: false,
                responsive: {
                    0: {
                        mouseDrag: true,
                        autoWidth: true,
                        center: true,
                        items: 2
                    },
                    600: {
                        items: 2,
                        center: true,
                        margin: 40,
                        autoWidth: false
                    }
                },
                onResize: function() {
                    $sliderArticles.trigger('to.owl.carousel', [0, 0, true]);
                }
            });

        };

        const sliderLogos = () => {
            $sliderLogos.owlCarousel({
                loop: false,
                items: 5,
                autoWidth: true,
                pullDrag: false,
                rewind: false,
                slideBy: 1,
                autoplay: false,
                responsive: {
                    0: {
                        center: false,
                        items: 2
                    },
                    640: {
                        center: true,
                        items: 3
                    },
                    768: {
                        items: 4
                    },
                    1200: {
                        items: 5,
                        mouseDrag: false
                    }
                },
                onResize: function() {
                    $sliderLogos.trigger('to.owl.carousel', [0, 0, true]);
                },
            });
        };

        const onResize = () => {
            window.addEventListener('resize', () => {

                if (window.innerWidth >= 1024) {
                    $sliderArticles.trigger('destroy.owl.carousel');
                } else {
                    sliderArticles();
                }

            });
        };

        const init = () => {
            onResize();
            sliderLogos();
            if (window.innerWidth < 1024) {
                sliderArticles();
            }
        };

        return {
            init
        };

    })();

    const forms = (() => {

        const contactForm = document.querySelector('.contact-form'),
            registerForm = document.querySelector('.register-form'),
            agreementsModal = document.getElementById('contact-form-agreements'),
            //agreementSelector = '#client_form_regulations',
            //agreementSelectorModal = '#client_form_regulations_modal',
            submitMessageSelector = '.form-submit-confirm',
            formOverlayActiveClass = 'form-loading',
            autosizeSelector = '.autosize';

        const textAreaGrow = () => {
            autosize(document.querySelectorAll(autosizeSelector));
        };

        // const toggleCheckbox = () => {
        //     $(agreementSelectorModal).on('change', (e) => {
        //         $(agreementSelector).prop('checked', e.target.checked);
        //     });
        // };

        const openAgreementsModal = () => {
            $('.contact-form-modal').on('click', () => {
                $(contactForm).parsley().validate();
                if ($(contactForm).parsley().isValid()) {
                    $.magnificPopup.open({
                        removalDelay: 400,
                        mainClass: 'mfp-fade',
                        items: {
                            src: agreementsModal
                        },
                        type: 'inline'
                    });
                }
            });
        };

        const contactSubmit = () => {
            $('.contact-form-submit').on('click', (e) => {
                e.preventDefault();
                //$(agreementSelectorModal).parsley().validate();

                //if ($(contactForm).parsley().isValid() && $(agreementSelectorModal).prop('checked')) {
                if ($(contactForm).parsley().isValid()) {
                    let data = $(contactForm).serializeArray();
                    submitContactForm(data);
                }
            });
        };

        const contactValidate = () => {
            // $(contactForm).parsley({
            //     excluded: '#client_form_regulations'
            // });

            $(contactForm).parsley()
                .on('field:error', function() {
                    let fieldWrapper = this.element.parentNode;
                    fieldWrapper.classList.remove('is-invalid');
                    fieldWrapper.classList.add('label-error');
                })
                .on('field:success', function() {
                    let fieldWrapper = this.element.parentNode;
                    fieldWrapper.classList.remove('label-error');
                });

            //toggleCheckbox();
            openAgreementsModal();
            contactSubmit();
        };

        const registerValidate = () => {
            $(registerForm).parsley();
            $(registerForm).on('submit', function(e) {
                e.preventDefault();

                let data = $(this).serializeArray();
                if ($(this).parsley().isValid()) {
                    validateEmail(data);
                }
            });
        };

        const toggleSubmitMessage = (options) => {
            let $message = $(options.form).find(submitMessageSelector);

            if ($message.length) {
                return options.hide ? $message.hide() : $message.slideDown();
            }

            if (options.reset) {
                options.form.reset();
                $(options.form).find('input, textarea').blur(); // remove focus
                $(options.form).find('.form-row').removeClass('is-dirty'); // remove active class
            }
        };

        const toggleFormOverlay = (options) => {
            if (options.form) {
                return options.overlay ? options.form.classList.add(formOverlayActiveClass) : options.form.classList.remove(formOverlayActiveClass);
            }
        };

        const submitContactForm = (data) => {

            toggleFormOverlay({
                form: agreementsModal,
                overlay: true
            });

            $.ajax({
                type: 'POST',
                url: '/send-message',
                dataType: "json",
                data: data,
                statusCode: {
                    200: () => {
                        toggleSubmitMessage({
                            form: agreementsModal,
                            hide: false
                        });

                        toggleSubmitMessage({
                            form: contactForm,
                            reset: true,
                            hide: false
                        });

                        //$(agreementSelectorModal).prop('checked', false);

                        setTimeout(function() {
                            $.magnificPopup.close();

                            toggleSubmitMessage({
                                form: agreementsModal,
                                hide: true
                            });
                        }, 3000);
                    },
                    409: (data) => {
                        if (data && data.responseText) {
                            console.log(JSON.parse(data.responseText));
                        }
                    }
                },
                complete: () => {
                    toggleFormOverlay({
                        form: agreementsModal,
                        overlay: false
                    });
                }
            });
        };

        const validateEmail = (data) => {
            toggleFormOverlay({
                form: registerForm,
                overlay: true
            });
            $.ajax({
                type: 'POST',
                url: '/create-user',
                dataType: "json",
                data: data,
                statusCode: {
                    200: () => {
                        registerForm.reset();
                        toggleSubmitMessage({
                            form: registerForm,
                            hide: false
                        });

                        setTimeout(function() {
                            $.magnificPopup.close();
                        }, 3000);

                        setTimeout(function() {
                            toggleSubmitMessage({
                                form: registerForm,
                                hide: true
                            });
                        }, 4000);
                    },
                    409: (data) => {
                        if (data && data.responseText) {
                            $('#register_email_form_email').parsley().addError('registererror', {
                                message: JSON.parse(data.responseText),
                                updateClass: true
                            });
                        }
                    }
                },
                complete: () => {
                    toggleFormOverlay({
                        form: registerForm,
                        overlay: false
                    });
                }
            });
        };

        const init = () => {
            if (contactForm) {
                textAreaGrow();
                contactValidate();
            }

            if (registerForm) {
                registerValidate();
            }
        };

        return {
            init
        };

    })();

    const videoPlayer = (() => {
        const videoSection = document.querySelector('.section-video'),
            video = videoSection.querySelector('video'),
            videoPlayTriggers = document.querySelectorAll('.video-trigger'),
            controlsContainer = videoSection.querySelector('.video-controls'),
            videoPauseTrigger = controlsContainer.querySelector('.video-pause'),
            progress = document.getElementById('progress'),
            progressBar = document.getElementById('progress-bar'),
            headerHeight = methods.getHeaderHeight(),

            selectors = {
                visible: 'video-visible',
                playing: 'video-playing',
                paused: 'video-paused'
            };

        const updateProgressBar = (mediaPlayer) => {
            let percentage = Math.floor((100 / mediaPlayer.duration) * mediaPlayer.currentTime);
            progressBar.style.width = percentage + '%';
        };

        const progressClick = () => {
            progress.addEventListener('click', (e) => {
                let percentage = (e.pageX - e.target.offsetLeft) / e.target.offsetWidth;
                video.currentTime = video.duration * percentage;
            });
        };

        const scrollToVideo = () => {
            $('html, body').animate({
                scrollTop: videoSection.offsetTop - headerHeight
            }, 300, () => {
                if (headerHeight !== methods.getHeaderHeight()) {
                    window.scroll(0, videoSection.offsetTop - methods.getHeaderHeight());
                }
            });
        };

        const videoStart = (e) => {
            e.preventDefault();
            videoSection.parentNode.classList.add(selectors.visible);
            controlsContainer.classList.remove(selectors.paused);
            controlsContainer.classList.add(selectors.playing);
            if (e.target.classList && e.target.classList.contains('btn-gradient')) {
                scrollToVideo();
            }
            videoPlay();
        };

        const videoPause = () => {
            controlsContainer.classList.remove(selectors.playing);
            controlsContainer.classList.add(selectors.paused);
            video.pause();
        };

        const videoEnd = () => {
            setTimeout(function() {
                videoSection.parentNode.classList.remove(selectors.visible);
                controlsContainer.classList.add(selectors.paused);
                controlsContainer.classList.remove(selectors.playing);
                video.currentTime = 0;
            }, 1500);
        };

        const videoPlay = () => video.play();

        const addEvents = () => {
            let triggerButtons = Array.prototype.slice.call(videoPlayTriggers);

            triggerButtons.map((el) => {
                el.addEventListener('click', videoStart);
            });

            videoPauseTrigger.addEventListener('click', videoPause);

            video.addEventListener('click', videoPause);

            video.addEventListener('timeupdate', () => {
                updateProgressBar(video);
            });

            video.addEventListener('ended', videoEnd);

            progressClick();
        };

        const init = () => {
            addEvents();
        };

        return {
            init
        };

    })();

    const layout = (() => {

        const leftAlign = Array.prototype.slice.call(document.querySelectorAll('.section-image-left')),
            rightAlign = Array.prototype.slice.call(document.querySelectorAll('.section-image-right')),
            elems = leftAlign.concat(rightAlign),
            minColumnsWidth = 992;

        const setPosition = () => {
            if (window.innerWidth >= minColumnsWidth) {
                Array.prototype.slice.apply(elems).map((el) => {
                    let article = el.querySelector('article'),
                        floatingColumn = el.querySelector('.floating-column');

                    if (el.classList.contains('section-image-left')) {
                        let offsetLeft = article.getBoundingClientRect().left;
                        floatingColumn.style.right = window.innerWidth - offsetLeft + 'px';

                    } else if (el.classList.contains('section-image-right')) {
                        let offsetRight = article.getBoundingClientRect().right;
                        floatingColumn.style.left = offsetRight + 'px';
                    }

                });
            }
        };

        const addEvents = () => {
            window.addEventListener('resize', setPosition);

        };

        const init = () => {
            addEvents();
            setPosition();
        };

        return {
            init
        };

    })();

    const scrolling = (() => {

        const counters = document.querySelectorAll('.counter-animate'),
            $menu = $('.main-header'),
            menuScrolledClass = 'menu-scrolled',
            menuNarrowClass = 'menu-narrow',
            menuNarrowOffset = 100,
            linkActiveClass = 'active',
            counterOptions = {
                // useEasing: false
                useEasing: function(x, t, b, c, d) {
                    return c * (t /= d) * t * t * t * t + b;
                }
            };

        let utmParams = location.search;

        const triggerCounters = () => {
            Array.prototype.slice.call(counters).map((counter) => {
                let elementWatcher = scrollMonitor.create(counter);

                elementWatcher.enterViewport(function() {
                    let endVal = counter.dataset['counter'];

                    if (!elementWatcher.counter && window.innerWidth > globals.desktopMin) {
                        let duration = 2;
                        let elCounter = new CountUp(counter, 0, endVal, 0, duration, counterOptions); // target, startVal, endVal, decimals, duration, options
                        setTimeout(() => {
                            elCounter.start();
                        }, globals.scrollAnimationDelay);
                        elementWatcher.counter = true;
                    }

                });
            });
        };

        const menuScrollClassToggle = () => {
            let mainWatcher = scrollMonitor.create($('.section-main'));

            mainWatcher.visibilityChange(function() {
                $menu.toggleClass(menuScrolledClass, !mainWatcher.isInViewport);
            });

            window.addEventListener('scroll', () => {
                if (window.pageYOffset > menuNarrowOffset) {
                    $menu.addClass(menuNarrowClass);
                } else {
                    $menu.removeClass(menuNarrowClass);
                }
            });
        };

        const smoothScroll = () => {
            $menu.on('click', 'a', function(e) {
                if (!this.classList.contains('btn')) {
                    e.preventDefault();
                    let targetSection = $(this.hash).length ? $(this.hash) : $('.section-main'),
                        offset = targetSection.offset().top - methods.getHeaderHeight();

                    $('html, body').animate({
                        scrollTop: offset
                    });
                }
            });
        };

        const setActiveSection = () => {
            const sections = [],
                $menuLinks = $menu.find('a:not(.btn)');

            function setActiveLink(hash) {
                $menuLinks.map((i, el) => {
                    if (el.hash === hash) {
                        el.classList.add(linkActiveClass);
                    } else {
                        el.classList.remove(linkActiveClass);
                    }
                });
            }

            $menuLinks.map((i, el) => {
                let $section = $(el.hash);

                if ($section.length) {
                    sections.push($section);
                }
            });

            sections.push($('.section-main'));

            sections.map((section) => {
                let elementWatcher = scrollMonitor.create(section);

                elementWatcher.enterViewport(function() {

                    if (utmParams) {
                        utmParams = false;
                        return;
                    }

                    let sectionHash = section[0].id ? '#' + section[0].id : window.location.pathname;
                    setActiveLink(sectionHash);

                    if (window.history && window.history.replaceState) {
                        history.replaceState(null, null, sectionHash);
                    } else {
                        location.hash = sectionHash;
                    }

                });
            });
        };

        const init = () => {
            smoothScroll();
            triggerCounters();
            setActiveSection();
            menuScrollClassToggle();
        };

        return {
            init
        };

    })();

    const modal = (() => {

        const options = {
            removalDelay: 400,
            mainClass: 'mfp-fade'
        };

        const addEvents = () => {
            $('.modal-open').magnificPopup(options);
        };

        const init = () => {
            addEvents();
        };

        return {
            init
        };

    })();

    const scrollAnimations = (() => {

        const options = {
            startEvent: 'load',
            duration: 500,
            offset: 300,
            once: true,
            anchor: 'top-center',
            disable: window.innerWidth <= globals.desktopMin,
            //easing: 'ease-in-out',
            delay: globals.scrollAnimationDelay
        };

        const init = () => {
            AOS.init(options);

            $(window).on('load', function() {
                AOS.refresh();
            });
        };

        return {
            init
        };

    })();

    const parallax = (() => {

        const $parallaxContainer = $('.parallax-container');

        let request = null,
            mouse = {
                x: 0,
                y: 0
            },
            cx = $parallaxContainer.width() / 2,
            cy = $parallaxContainer.height() / 2;

        const update = () => {
            dx = cx - mouse.x;
            dy = cy - mouse.y;

            tiltx = (dy / cy);
            tilty = -(dx / cx);
            radius = Math.sqrt(Math.pow(tiltx, 2) + Math.pow(tilty, 2));
            degree = (radius * 10);
            TweenLite.to('.parallax-image', 1, {
                transform: 'translate3d(' + dx / 20 + 'px,' + dy / 20 + 'px, 0px)',
                ease: Power2.easeOut
            });
        };

        const addEvents = () => {

            $parallaxContainer.mousemove(function(event) {
                if (window.innerWidth >= globals.desktopMin) {
                    mouse.x = event.pageX - this.offsetLeft;
                    mouse.y = event.pageY - this.offsetTop;
                    cancelAnimationFrame(request);
                    request = requestAnimationFrame(update);
                }
            });

            $(window).resize(function() {
                cx = window.innerWidth / 2;
                cy = window.innerHeight / 2;
            });
        };

        const init = () => {
            addEvents();
        };

        return {
            init
        };

    })();

    mobileMenu.init();
    carousel.init();
    forms.init();
    videoPlayer.init();
    layout.init();
    scrolling.init();
    modal.init();
    scrollAnimations.init();
    parallax.init();

})();